<template>
  <v-row>
    <v-row class="w-full">
      <v-col cols="12" xs="2" md="2" lg="2" xl="2">
        <v-switch v-model="obContract.active" :label="$t('active')" />
      </v-col>

      <v-col cols="12" xs="2" md="4" lg="4" xl="4">
        <v-switch
          v-model="useDefaultSettings"
          :label="$t('default.settings')"
          @change="onToggleDefaultSettings"
        />
      </v-col>
    </v-row>

    <v-row class="w-full">
      <v-col cols="12" md="6" lg="3" xl="3">
        <form-field-text v-model="obContract.name" required />
      </v-col>

      <v-col cols="12" md="2" lg="1">
        <code-field-text
          v-model="obContract.code"
          auto-assign
          item-key="contract"
          required
        />
      </v-col>

      <v-col cols="12" md="4" lg="2">
        <contract-category-select v-model="obContract.contract_category_id" />
      </v-col>

      <v-col cols="12" md="6" lg="3" xl="3">
        <customer-select
          v-model="obContract.customer_id"
          required
          @change="onSelectCustomer"
        />
      </v-col>

      <v-col v-if="obContract.customer_id" cols="12" md="6" lg="3" xl="3">
        <branch-select
          :value="obContract.branch_id"
          :customer-id="obContract.customer_id"
          @change="onSelectBranch"
          :label="$t('branch')"
          select
          tag="div"
        />
      </v-col>
    </v-row>

    <v-row class="w-full">
      <v-col cols="12">
        <v-textarea
          v-model="obContract.description"
          :label="$t('addenda')"
          outlined
        />
      </v-col>
      <time-period-field v-model="obContract.period" infinite hide-toggle-btn />
    </v-row>

    <v-row class="w-full">
      <template v-if="useDefaultSettings">
        <v-col cols="12" md="6" lg="3" xl="3">
          <form-field-date-picker
            v-model="obDefaultData.start_at"
            label="from"
          />
        </v-col>

        <v-col cols="12" md="6" lg="3" xl="3">
          <form-field-date-picker
            v-model="obDefaultData.end_at"
            :min="obDefaultData.start_at"
            label="to"
          />
        </v-col>

        <v-col cols="12" md="6" lg="3" xl="3">
          <accounting-movement-type-select
            v-model="obDefaultData.accounting_movement_type_id"
            :disabled="!obCustomer"
            market-type="buy"
            omit-market-type
            required
            @change="onSelectMovementType"
          />
        </v-col>

        <v-col cols="12" md="6" lg="3" xl="3">
          <currency-select
            v-model="obDefaultData.currency_id"
            :disabled="!obCustomer"
            :ids="arCustomerCurrencyIdList"
            required
          />
        </v-col>

        <v-col cols="12" md="6" lg="3" xl="3">
          <payment-terms-select
            v-model="obDefaultData.payment_term_id"
            :disabled="!isCredit"
            :payment-terms="arCustomerPaymentTermList"
          />
        </v-col>
      </template>
    </v-row>

    <v-col cols="12">
      <sheet dense depressed light outlined>
        <positions-table :disabled="!valid" />
      </sheet>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Provide, VModel, Vue } from "vue-property-decorator";
import type {
  AccountingMovementType,
  ContractPosition,
  ContractPositionData,
  Customer,
  TimePeriodData,
} from "@planetadeleste/vue-mc-gw";
import { Contract, TimePeriod } from "@planetadeleste/vue-mc-gw";
import CodeFieldText from "@/components/form/fields/CodeFieldText.vue";
import CustomerSelect from "@/modules/customers/components/CustomerSelect.vue";
import PositionsTable from "@/modules/contracts/components/tabs/PositionsTable.vue";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import AccountingMovementTypeSelect from "@/modules/accountingentries/components/AccountingMovementTypeSelect.vue";
import { isEmpty, isNil, map, pick } from "lodash";
import PaymentTermsSelect from "@/modules/paymentterms/components/PaymentTermsSelect.vue";
import TimePeriodField from "@/components/form/TimePeriodField.vue";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import BranchSelect from "@/modules/companies/components/BranchSelect.vue";
import type { PaymentTermData } from "@planetadeleste/vue-mc-gw/src/types";
import { ref } from "@/services/Utils";
import type { Branch } from "@planetadeleste/vue-mc-gw";
import ContractCategorySelect from "@/modules/contractcategories/components/ContractCategorySelect.vue";

@Component({
  components: {
    ContractCategorySelect,
    FormFieldDatePicker,
    TimePeriodField,
    PaymentTermsSelect,
    AccountingMovementTypeSelect,
    CurrencySelect,
    PositionsTable,
    CustomerSelect,
    CodeFieldText,
    BranchSelect,
  },
})
export default class ContractsSettings extends Vue {
  @VModel({ type: Object, default: () => new Contract() })
  obContract!: Contract | any;
  @Prop(Boolean) readonly valid!: boolean;
  // @Provide() contract: Ref<Contract | null> = { value: null };
  @Provide() customer = ref<Customer | null>(null);
  @Provide() defaultSettings = ref<Partial<ContractPositionData>>({});

  useDefaultSettings = false;
  obDefaultData: Partial<ContractPositionData> = {};
  obCustomer: Customer | null = null;
  obMovementType: AccountingMovementType | null = null;

  get arCustomerCurrencyIdList(): number[] {
    return this.obCustomer ? map(this.obCustomer.currencies, "id") : [];
  }

  get arCustomerPaymentTermList(): Partial<PaymentTermData>[] {
    return this.obCustomer ? this.obCustomer.paymentterms : [];
  }

  get isCredit(): boolean | undefined {
    return (
      this.useCashCredit &&
      !!this.obMovementType &&
      !this.obMovementType.is_cash
    );
  }

  get useCashCredit(): boolean {
    return (
      !!this.obMovementType && this.obMovementType.get("use_cash_credit", false)
    );
  }

  get obPeriod(): Partial<TimePeriodData> | TimePeriod {
    return this.obContract.get("period", {});
  }

  onSelectCustomer(obData: Customer) {
    this.customer.value = obData;
    this.obCustomer = obData;
  }

  onSelectBranch(obData: Branch) {
    this.obContract.set("branch_id", obData.id);
  }

  onSelectMovementType(obItem: AccountingMovementType) {
    this.obMovementType = obItem;

    if (this.useDefaultSettings && !this.isCredit) {
      this.$set(this.obDefaultData, "payment_term_id", null);
    }

    this.updateDefaultSettings();
  }

  onToggleDefaultSettings(bValue: boolean) {
    this.obContract.set("default_settings", bValue);
    if (!bValue) {
      this.obDefaultData = {};
      this.updateDefaultSettings();
    }
  }

  updateDefaultSettings() {
    this.defaultSettings.value = this.obDefaultData;
  }

  setInitialDefaultData() {
    if (!this.obContract.id || !this.obContract.positions?.length) {
      return;
    }

    const arKeyList: (keyof ContractPositionData)[] = [
      "currency_id",
      "payment_term_id",
      "accounting_movement_type_id",
      "start_at",
      "end_at",
    ];
    const arPositions = this.obContract.get(
      "positions",
      []
    ) as ContractPosition[];

    if (!arPositions.length) {
      return;
    }

    if (this.useDefaultSettings) {
      const obPosition = arPositions[0];
      const obPositionPartialData = pick(obPosition.attributes, arKeyList);
      this.obDefaultData = obPositionPartialData;
    }
  }

  mounted() {
    // this.contract.value = this.obContract;

    if (isEmpty(this.obPeriod) || isNil(this.obPeriod)) {
      this.obContract.set(
        "period",
        new TimePeriod({
          frequency: "month",
          frequency_interval: 1,
          delay: 1,
          cycles: 1,
          fixed: false,
        })
      );
    }
    this.useDefaultSettings = this.obContract.get("default_settings");

    this.setInitialDefaultData();
    this.updateDefaultSettings();
  }
}
</script>
